import React from 'react';

function Email() {
    return (
        <a href="mailto:mail@f-hanholz.de">mail@f-hanholz.de</a>
    )
}

function Handy() {
    return (
        <span>015756640391</span>
    );
}

function Kontakt(props) {

    var showName = props.showName || false;

    function printName() {
        if(showName) {
            return (
                <tr>
                    <td colSpan="2">Frederika Kortmann</td>
                </tr>
            )
        }
        else {
            return;
        }
    }

    return (
        <div>
            <table>
                {printName()}
                <tr>
                    <td>Telefon:</td>
                    <td className="pl-2"><Handy /></td>
                </tr>
                <tr>
                    <td>E-Mail:</td>
                    <td  className="pl-2"><Email /></td>
                </tr>
            </table>
        </div>
    )
}

export {
    Email,
    Handy,
    Kontakt
}
